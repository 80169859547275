import { fetchReportParams, fetchReports, fetchAdminReports, fetchAdminReportParams } from './ReportsApi'

const queries = {
  getReportParams: {
    name: ['reports', 'getParams'],
    request: fetchReportParams,
  },
  listReports: {
    name: ['reports', 'list'],
    request: fetchReports,
  },
  getAdminReportParams: {
    name: ['adminReports', 'getParams'],
    request: fetchAdminReportParams,
  },
  listAdminReports: {
    name: ['adminReports', 'list'],
    request: fetchAdminReports,
  },
}

export default queries
