export const translation = {
  logout: 'Déconnexion',
  userSettings: 'Voir mon profil',
  deploymentHistory: 'Historique des déploiements',
  notConnected: 'Pas connecté',
  otherLanguage: 'English',
  tabTitle: 'Portail PMP Solutions',
  buttons: {
    cancel: 'Annuler',
    submit: 'Soumettre',
  },
  reports: {
    loading: 'Chargement...',
    errorLoading: 'Erreur lors du chargement des rapports',
    pleaseSelectAReport: 'Veuillez sélectionner un rapport pour le visualiser.',
    reportListTitle: 'Rapports Power BI',
    noReportSelected: 'Aucun rapport de sélectionné',
  },
  help: {
    needHelp: "Besoin d'aide ?",
    helpCenter: "Centre d'aide",
    supportRequests: 'Support / Demandes',
    bookMeeting: 'Réserver une réunion',
    email: ' courriel',
    visit: 'Visitez pmpsolutions.ca',
  },
  supportForm: {
    name: 'Nom',
    email: 'Adresse courriel',
    millName: "Nom de l'usine",
    phone: 'Téléphone',
    addFiles: 'Ajouter des fichiers',
    title: 'Titre',
    details: 'Décrivez votre problème. Ajoutez des détails pour que nous puissions vous aider plus efficacement.',
    description: 'Description',
    descriptionRequired: 'La description est requise',
    errorMessage: "Une erreur s'est produite lors de la soumission du ticket.",
  },
  dashboards: {
    refreshEvery5Minutes:
      "Les tableaux de bord sont rafraichis aux 5 minutes même sans connexion au réseau local de l'usine",
    dashboards: 'Tableaux de bord',
    openLiveDashboard: 'Ouvrir le tableau de bord en direct',
  },
  products: {
    myProducts: 'Mes produits',
    otherProducts: 'Autres produits',
    inConstruction: 'Cette page est en construction',
  },
  resourceReports: {
    reports: 'Rapports',
    selectEquipment: 'Sélectionner un équipement',
    browseAllReports: 'Parcourir tous les rapports disponibles',
    shift: 'Quart :',
    day: 'Jour :',
    current: 'Actuel',
    last: 'Dernier',
    fiscal: 'Fiscal',
    lastFiscal: 'Dernier fiscal',
    today: 'Aujourd’hui',
    week: 'Semaine :',
    month: 'Mois :',
    year: 'Année :',
    yesterday: 'Hier',
    currentFiscal: 'Fiscal actuel',
    unavailable: 'Rapport non disponible',
  },
  tools: {
    tools: 'Outils',
    downtimeDocumentation: 'Documentation des temps d’arrêt',
    editGaugeLimits: 'Modifier les limites opérationnelles',
    reportsPortal: 'Portail des rapports',
  },
  connection: {
    dashboardAndToolsNotAvailable:
      'Les tableaux de bord et les outils en direct ne sont disponibles que lorsque vous êtes connectés au réseau de votre usine',
  },
  userSettingsPage: {
    title: 'Paramètres d’utilisateur',
    phoneNumber: 'Numéro de téléphone :',
    organizationName: 'Nom de l’organisation :',
    email: 'Adresse courriel :',
    name: 'Nom d’utilisateur :',
    password: 'Modifiez votre mot de passe',
    here: 'ici',
    save: 'Sauvegarder',
    saving: 'En sauvegarde...',
    cancel: 'Annuler',
    failSave: 'Impossible d’enregistrer les détails',
    successSave: 'Détails enregistrés avec succès',
    invalidName: 'Nom d’utilisateur invalide',
    invalidPhone: 'Numéro de téléphone invalide',
  },
  deploymentHistoryPage: {
    title: 'Historique des déploiements',
  },
  updateAlert: {
    newVersionAvailable: 'Une nouvelle version de l’application est disponible. La page se rafraîchira dans ',
    seconds: 'secondes',
    now: 'Rafraîchir maintenant',
  },
}
