import { Box, Typography, Card, CardContent, Grid } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useAdminReportList } from '../reports/services/ReportsHooks'

const DeploymentHistoryLayout: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { reports, isLoading, error } = useAdminReportList()

  if (isLoading) {
    return <div>{t('reports.loading')}</div>
  }

  if (error) {
    return <div>{t('reports.errorLoading')}</div>
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        PMP Admin
      </Typography>

      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Power BI Reports
          </Typography>

          {reports && reports.length > 0 ? (
            <Grid container spacing={2}>
              {reports.map((report) => (
                <Grid item xs={12} md={4} key={report.id}>
                  <Card
                    sx={{
                      p: 2,
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: 'action.hover',
                      },
                    }}
                    onClick={() => navigate(`/adminReports/${report.workspaceId}/${report.id}`)}
                  >
                    <Typography variant="subtitle1">{report.name}</Typography>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Typography>No admin reports available</Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

export default DeploymentHistoryLayout
