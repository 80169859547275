import { useQuery } from 'react-query'

import { UnauthorizedError } from '../../auth/UnauthorizedError'
import { clearAuth } from '../../store/authSlice'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { Report } from '../../types'
import ReportsQueries from './ReportsQueries'

function compareReports(a: Report, b: Report) {
  if (a.reportType > b.reportType) {
    return -1
  }
  if (a.reportType < b.reportType) {
    return 1
  }
  return a.name.localeCompare(b.name)
}

export const useReportList = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const dispatch = useAppDispatch()
  const { name, request } = ReportsQueries.listReports
  const { data, isLoading, error } = useQuery(name, () => request(accessToken!), {
    enabled: Boolean(accessToken),
    select: (reports) => reports.sort(compareReports),
  })

  if (error instanceof UnauthorizedError) {
    dispatch(clearAuth())
  }

  return { reports: data, isLoading, error }
}

export const useAdminReportList = () => {
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const dispatch = useAppDispatch()
  const { name, request } = ReportsQueries.listAdminReports
  const { data, isLoading, error } = useQuery(name, () => request(accessToken!), {
    enabled: Boolean(accessToken),
    select: (reports) => reports.sort(compareReports),
  })

  if (error instanceof UnauthorizedError) {
    dispatch(clearAuth())
  }

  return { reports: data, isLoading, error }
}

export const useReportParams = (workspaceId: string, reportId: string) => {
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const dispatch = useAppDispatch()
  const { name, request } = ReportsQueries.getReportParams
  const { data, error } = useQuery(
    [...name, workspaceId, reportId],
    () => request(accessToken!, workspaceId, reportId),
    { enabled: Boolean(accessToken), cacheTime: 0 },
  )

  if (error instanceof UnauthorizedError) {
    dispatch(clearAuth())
  }

  return { reportParams: data, error }
}
export const useAdminReportParams = (workspaceId: string, reportId: string) => {
  const accessToken = useAppSelector((state) => state.auth.accessToken)
  const dispatch = useAppDispatch()
  const { name, request } = ReportsQueries.getAdminReportParams
  const { data, error } = useQuery(
    [...name, workspaceId, reportId],
    () => request(accessToken!, workspaceId, reportId),
    { enabled: Boolean(accessToken), cacheTime: 0 },
  )

  if (error instanceof UnauthorizedError) {
    dispatch(clearAuth())
  }

  return { reportParams: data, error }
}
