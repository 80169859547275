import { makeStyles } from '@mui/styles'
import MuiPhoneNumber from 'mui-phone-number'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { updateUserPhone, updateUserName, fetchUserDetails } from '../services/apiService'
import { setUserDetails } from '../store/authSlice'
import { useAppSelector, useAppDispatch } from '../store/hooks'
import { RootState } from '../store/store'

const useStyles = makeStyles({
  container: {
    maxWidth: 650,
    margin: '0 auto',
    padding: 20,
    borderRadius: 3,
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    '@media (max-width: 600px)': {
      padding: 15,
      width: '100%',
    },
  },
  title: {
    fontSize: 20,
    marginBottom: 16,
    fontWeight: 600,
    color: '#333333',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 12,
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  label: {
    flex: 1,
    fontSize: 14,
    fontWeight: 450,
    color: '#333333',
    marginRight: 10,
    '@media (max-width: 600px)': {
      marginBottom: 5,
    },
  },
  input: {
    flex: 2,
    width: 203,
    padding: 10,
    fontSize: 14,
    border: '1px solid #ccc',
    borderRadius: 2,
    '&:focus': {
      borderColor: '#0078d4',
      boxShadow: '0 0 4px rgba(0, 120, 212, 0.8)',
      outline: 'none',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    color: '#d93025',
    fontSize: 12,
    marginTop: 5,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 8,
  },
  button: {
    padding: '8px 16px',
    fontSize: 14,
    border: '1px solid transparent',
    borderRadius: 3,
    cursor: 'pointer',
    transition: 'background-color 0.2s, border-color 0.2s',
    '&:hover': {
      borderColor: '#005a9e',
    },
    '&.save': {
      backgroundColor: '#0078d4',
      color: 'white',
      '&:hover': {
        backgroundColor: '#005a9e',
      },
    },
    '&.cancel': {
      backgroundColor: '#cecece',
      color: 'white',
      '&:hover': {
        backgroundColor: '#c12e2a',
      },
    },
  },
  buttonDisabled: {
    backgroundColor: '#cecece',
    color: 'white',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: '#005a9e',
    },
  },
  notification: {
    padding: 10,
    marginBottom: 15,
    borderRadius: 3,
    backgroundColor: '#e1f5fe',
    color: '#0277bd',
    border: '1px solid #b3e5fc',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  linkField: {
    flex: 1,
    color: '#0078d4',
    textAlign: 'right',
    fontSize: 14,
    textDecoration: 'underline',
    '&:hover': {
      textDecoration: 'none',
      color: '#aaaaaa',
    },
    '&:visited': {
      color: '#0078d4',
    },
    '&:active': {
      color: '#0078d4',
    },
    marginTop: 10,
    marginBottom: 10,
    '@media (max-width: 600px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
})

const UserSettings = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const userDetails = useAppSelector((state: RootState) => state.auth.userDetails)
  const accessToken = useAppSelector((state: RootState) => state.auth.accessToken)

  const [phone, setPhone] = useState(userDetails?.phoneNumber || '+1')
  const [name, setName] = useState(userDetails?.fullName || '')
  const [isEditing, setIsEditing] = useState(false)
  const [nameChanged, setNameChanged] = useState(false)
  const [phoneChanged, setPhoneChanged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [notification, setNotification] = useState('')
  const [errors, setErrors] = useState({ phone: '', fullName: '' })
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  const frontendUrl = process.env.REACT_APP_FRONTEND_URL
  const clientId = process.env.REACT_APP_B2C_CLIENT_ID
  const instanceWithHttps = process.env.REACT_APP_B2C_INSTANCE
  const domain = process.env.REACT_APP_B2C_DOMAIN
  const policy = process.env.REACT_APP_B2C_PASSWORDRESET_POLICY

  if (!frontendUrl) throw 'Invalid configuration. Please set REACT_APP_FRONTEND_URL.'
  if (!clientId) throw 'Invalid configuration. Please set REACT_APP_B2C_CLIENT_ID.'
  if (!instanceWithHttps) throw 'Invalid configuration. Please set REACT_APP_B2C_INSTANCE.'
  if (!domain) throw 'Invalid configuration. Please set REACT_APP_B2C_DOMAIN.'
  if (!policy) throw 'Invalid configuration. Please set REACT_APP_B2C_PASSWORDRESET_POLICY.'

  const instance = instanceWithHttps.replace('https://', '')

  const passwordresetUrl = `https://${instance}/${domain}/${policy}/oauth2/v2.0/authorize?p=${policy}&client_id=${clientId}&nonce=defaultNonce&redirect_uri=${frontendUrl}/&scope=openid&response_type=id_token&prompt=login`

  const validatePhone = (value: string) => {
    const isValid = /^\+1\d{10}$/.test(value)
    setErrors((prevErrors) => ({ ...prevErrors, phone: isValid ? '' : t('userSettingsPage.invalidPhone') }))
    return isValid
  }

  const validateName = (value: string) => {
    const isValid = /^[A-Za-z]+(?:-[A-Za-z]+)? [A-Za-z]+(?:-[A-Za-z]+)?$/.test(value)
    setErrors((prevErrors) => ({ ...prevErrors, fullName: isValid ? '' : t('userSettingsPage.invalidName') }))
    return isValid
  }

  useEffect(() => {
    setIsButtonEnabled(nameChanged || phoneChanged)
  }, [nameChanged, phoneChanged])

  useEffect(() => {
    // Fetch user details from the store when the component mounts
    if (!userDetails) {
      fetchData()
    } else {
      setPhone(userDetails.phoneNumber || '+1')
      setName(userDetails.fullName || '')
    }
  }, [userDetails, dispatch])

  const fetchData = async () => {
    try {
      if (accessToken) {
        const userData = await fetchUserDetails(accessToken)
        dispatch(setUserDetails(userData))
        setPhone(userData.phoneNumber || '+1')
        setName(userData.fullName || '')
      }
    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }

  const handleSave = async () => {
    if (!validatePhone(phone) || !validateName(name)) return
    setLoading(true)
    setIsButtonEnabled(false) // Disable button after clicking
    try {
      if (accessToken) {
        if (phoneChanged) await updateUserPhone(accessToken, phone)
        if (nameChanged) await updateUserName(accessToken, name)
        setUserDetails({
          email: userDetails?.email || '',
          phoneNumber: phone,
          fullName: name,
          organizationName: userDetails?.organizationName || '',
          language: userDetails?.language || 'EN',
          isAdmin: userDetails?.isAdmin || false,
        })
        setNotification(t('userSettingsPage.successSave'))
        setNameChanged(false)
        setPhoneChanged(false)
      }
    } catch (error) {
      setNotification(t('userSettingsPage.failSave'))
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = () => {
    setPhone(userDetails?.phoneNumber || '')
    setName(userDetails?.fullName || '')
    setIsEditing(false)
    setNameChanged(false)
    setPhoneChanged(false)
    setIsButtonEnabled(false)
  }

  return (
    <div className={classes.container}>
      <h2 className={classes.title}>{t('userSettingsPage.title')}</h2>

      {notification && (
        <div className={classes.notification}>
          {notification}
          <button onClick={() => setNotification('')}>✖</button>
        </div>
      )}

      <div className={classes.row}>
        <label htmlFor="organizationName" className={classes.label}>
          {t('userSettingsPage.organizationName')}
        </label>
        <span id="organizationName">{userDetails?.organizationName}</span>
      </div>

      <div className={classes.row}>
        <label htmlFor="email" className={classes.label}>
          {t('userSettingsPage.email')}
        </label>
        <span id="email">{userDetails?.email}</span>
      </div>

      <div className={classes.row}>
        <label htmlFor="name" className={classes.label}>
          {t('userSettingsPage.name')}
        </label>
        <div className={classes.inputContainer}>
          <input
            id="name"
            type="text"
            className={classes.input}
            value={name}
            onChange={(e) => {
              setName(e.target.value)
              validateName(e.target.value)
              setNameChanged(true)
            }}
          />
          {errors.fullName && <div className={classes.error}>{errors.fullName}</div>}
        </div>
      </div>

      <div className={classes.row}>
        <label htmlFor="phone" className={classes.label}>
          {t('userSettingsPage.phoneNumber')}
        </label>
        <div className={classes.inputContainer}>
          <MuiPhoneNumber
            defaultCountry="ca"
            id="phone"
            value={isEditing ? phone : phone || ''}
            onChange={(value) => {
              if (typeof value === 'string') {
                let normalizedValue = value.replace(/[^+\d]/g, '')
                // Ensure the phone number always starts with +1
                if (!normalizedValue.startsWith('+1')) {
                  normalizedValue = '+1' + normalizedValue.replace(/^\+?1?/, '')
                }
                // Limit the total length to 12 characters (+1 plus 10 digits)
                normalizedValue = normalizedValue.slice(0, 12)
                setPhone(normalizedValue)
                validatePhone(normalizedValue)
                setPhoneChanged(true)
              }
            }}
            onFocus={() => setIsEditing(true)}
            disableDropdown={!isEditing}
            autoFormat
            regions={'north-america'}
            className={classes.input}
            error={!!errors.phone}
          />
          {errors.phone && <div className={classes.error}>{errors.phone}</div>}
        </div>
      </div>

      <div className={classes.row}>
        <a className={classes.linkField} href={passwordresetUrl}>
          {t('userSettingsPage.password')}
        </a>
      </div>

      <div className={classes.buttons}>
        <button
          className={`${classes.button} ${isButtonEnabled ? 'save' : classes.buttonDisabled}`}
          onClick={handleSave}
          disabled={loading || !isButtonEnabled}
        >
          {t('userSettingsPage.save')}
        </button>
        <button className={`${classes.button} cancel`} onClick={handleCancel} disabled={loading}>
          {t('userSettingsPage.cancel')}
        </button>
      </div>
    </div>
  )
}

export default UserSettings
