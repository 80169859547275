import { makeStyles } from '@mui/styles'
import { EmbedType, PowerBIEmbed } from 'powerbi-client-react'
import { BackgroundType, TokenType } from 'powerbi-models'
import { Navigate } from 'react-router'

import { NotFoundError } from '../../errors/NotFoundError'
import { CustomEmbedSettings } from '../../types'
import { useReportParams, useAdminReportParams } from '../services/ReportsHooks'

interface Props {
  reportId: string
  workspaceId: string
  settings?: CustomEmbedSettings
  isAdminOnly?: boolean
}

const useStyles = makeStyles(() => ({
  report: {
    height: '100%',
    width: '100%',
  },
}))
const ReportViewer = ({ reportId, workspaceId, settings, isAdminOnly }: Props) => {
  const styles = useStyles()
  const { reportParams, error } = isAdminOnly
    ? useAdminReportParams(workspaceId, reportId)
    : useReportParams(workspaceId, reportId)

  if (error instanceof NotFoundError) {
    return <Navigate to="/" />
  }

  if (!reportParams) {
    return null
  }

  return (
    <PowerBIEmbed
      embedConfig={{
        type: EmbedType.Report,
        id: reportParams.embedReport.id,
        embedUrl: reportParams.embedReport.embedUrl,
        accessToken: reportParams.embedToken.token,
        tokenType: TokenType.Embed,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
          background: BackgroundType.Default,
          ...settings,
        },
      }}
      eventHandlers={
        new Map([
          [
            'error',
            function (event) {
              console.error('Report error', event?.detail)
            },
          ],
        ])
      }
      cssClassName={styles.report}
    />
  )
}

export default ReportViewer
